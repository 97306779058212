import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import tw, { styled } from 'twin.macro'

import {Layout} from '@components/layout/layout'
import { CenterContainer, ListDisc, MainContainer, RightContainer } from "@components/oferta.style"
import { H1, H3, Paragph } from "@components/layout/style"

const Grid = styled('div')`
${tw`grid grid-flow-col grid-cols-2 auto-rows-auto gap-4 leading-normal`}
`

const Ol = styled('Ol')`
${tw`list-decimal px-4`}
`

const RodoTemplate:React.FC = ({data}) => (<Layout data={data}
  prop={{
    canonicalUrl: 'https://www.mediroot.pl/regulamin-mediroot/',
    title: 'Rodo - usługi stomatologiczne | Mediroot',
    description: 'Mediroot ➤ Stomatolog Wągrowiec - Stomatologia zachowawcza ✔ Stomatologia dziecięca ✔ Ortodoncja ✔ Protetyka stomatologiczna ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
  }}>
  <StaticImage
    src="../assets/images/hero.jpg"
    placeholder="blurred"
    alt="Hero image"
    layout="fullWidth" />

  <CenterContainer>
      <H1>
      REGULAMIN ORGANIZACYJNY PODMIOTU LECZNICZEGO MEDIROOT
      </H1>

      <H3>§ 1</H3>
      <Paragph>
        <Ol>
            <li>
            Regulamin określa organizację i porządek procesu udzielania świadczeń zdrowotnych przez podmiot leczniczy, którym jest przedsiębiorca Urszula Korzeniowska zwany dalej „podmiotem leczniczym”.
            </li>
            <li>
            Podmiot leczniczy wskazany w ust. 1 prowadzi przedsiębiorstwo pod nazwą MEDIROOT Urszula Korzeniowska w Wągrowcu przy ulicy Św. Wojciecha 16.
            </li>
        </Ol>        
      </Paragph>

      <H3>§ 2</H3>
      <Paragph>
        Regulamin obowiązuje:
       <ListDisc>
            <li>
            wszystkie osoby zatrudnione przez przedsiębiorcę wskazanego w  ramach prowadzonej działalności  
        leczniczej, niezależnie od podstaw prawnych zatrudnienia;
            </li>
            <li>
            wszystkich pacjentów, którym udzielane są świadczenia zdrowotne.
            </li>
       </ListDisc>
      </Paragph>

      <H3>§ 3</H3>
      <Paragph>
        Podstawowym celem podmiotu MEDIROOT jest udzielanie świadczeń zdrowotnych przez osoby do tego uprawnione na podstawie przepisów prawa.
      </Paragph>

      <H3>§ 4</H3>
      <Paragph>
      Struktura podmiotu leczniczego:
      I. Nazwa przedsiębiorstwa podmiotu leczniczego : MEDIROOT Urszula Korzeniowska <br/>
      Wągrowiec, ul. św. Wojciecha 16 <br/>
      II. Zaklady lecznicze: MEDIROOT Zakład Leczniczy <br/>
      Wągrowiec, ul. św. Wojciecha 16 <br/>
      III.  Jednostki organizacyjne MEDIROOT Zakład Leczniczy: MEDIROOT <br/>
      Wągrowiec, ul. św. Wojciecha 16 <br/>
      IV. Komórki organizacyjne jednostrki MEDIROOT <br/>
     <ListDisc>
        <ol>
            PORADNIA STOMATOLOGICZNA<br/>
            Wągrowiec, ul. św. Wojciecha 16 <br/>
        </ol>
        <ol>
            PORADNIA STOMATOLOGICZNA DLA DZIECI<br/>
            Wągrowiec, ul. św. Wojciecha 16 <br/>
        </ol>
        <ol>
            Gabinet nr 1<br/>
            Budzyń, ul. Rogozińska 52
        </ol>
        <ol>
            Gabinet nr 1 dzieci<br/>
            Budzyń, ul. Rogozińska 52
        </ol>
        <ol>
            Gabinet nr 2 <br/>
            Oborniki, ul. Obrzycka 88
        </ol>
        <ol>
            Gabinet nr 2 dzieci<br/>
            Oborniki, ul. Obrzycka 88
        </ol>

        <ol>
            Gabinet nr 3 <br/>
            Ryczywół, ul. Szkolna 1
        </ol>
        <ol>
            Gabinet nr 3 dzieci<br/>
            Ryczywół, ul. Szkolna 1
        </ol>
        
        <ol>
            Gabinet nr 4 <br/>
            Gąsawa, ul. Leszka Białego 6
        </ol>
        <ol>
            Gabinet nr 4 dzieci<br/>
            Gąsawa, ul. Leszka Białego 6
        </ol>
        
        <ol>
            Gabinet nr 4 <br/>
            Gąsawa, ul. Leszka Białego 6
        </ol>
        <ol>
            Gabinet nr 4 dzieci<br/>
            Gąsawa, ul. Leszka Białego 6
        </ol>

        <ol>
            Gabinet nr 5 <br/>
            Kłecko, ul. Gnieźnińska 7A
        </ol>
        <ol>
            Gabinet nr 5 dzieci<br/>
            Kłecko, ul. Gnieźnińska 7A
        </ol>
        
        <ol>
            Gabinet nr 6 <br/>
            Żnin, ul. Wandy Pieniężnej 19
        </ol>
        <ol>
            Gabinet nr 6 dzieci<br/>
            Żnin, ul. Wandy Pieniężnej 19
        </ol>
        
        <ol>
            Gabinet nr 7 <br/>
            Żnin, ul. Szkolna 4
        </ol>
        <ol>
            Gabinet nr 7 dzieci<br/>
            Żnin, ul. Szkolna 4
        </ol>
        
        <ol>
            Gabinet nr 8 <br/>
            Wągrowiec, ul. Letnia 1
        </ol>
        <ol>
            Gabinet nr 8 dzieci<br/>
            Wągrowiec, ul. Letnia 1
        </ol>
     </ListDisc>
      </Paragph>
        
        <H3>§ 5</H3>
        <Paragph>
            <Ol>
                <li>
                    Do głównych zadań  komórki  organizacyjnej należy
                    <ListDisc>
                        <ol>
                        realizacja zadań podmiotu i celu, dla którego został on utworzony, określonych w powszechnie  
        obowiązujących przepisach prawa oraz niniejszym Regulaminie;
                        </ol>
                        <ol>
                        świadczenia z zakresu stomatologii zachowawczej z endodoncją
                        </ol>
                        <ol>
                        świadczenia z zakresu protetyki stomatologicznej
                        </ol>
                        <ol>
                        świadczenia z zakresu chirurgii stomatologicznej
                        </ol>
                        <ol>
                        świadczenia z zakresu stomatologii dziecięcej
                        </ol>
                        <ol>
                        świadczenia z zakresu periodontologii
                        </ol>
                        <ol>
                        świadczenia z zakresu ortodoncji
                        </ol>
                        <ol>
                        radiologia i diagnostyka obrazowa
                        </ol>
                    </ListDisc>
                </li>
                <li>
                    Do podstawowych zadań komórki organizacyjnej należy:
                    <ListDisc>
                        <ol>
                            badanie i porada lekarska,
                        </ol>
                        <ol>
                            określenie zaawansowania zachorowania pacjenta;
                        </ol>
                        <ol>
                        określenie kierunku leczenia;
                        </ol>
                        <ol>
                            postawienie diagnozy;
                        </ol>
                        <ol>
                            orzekanie i opiniowanie o stanie zdrowia;
                        </ol>
                        <ol>
                            opieka profilaktyczna;
                        </ol>
                        <ol>
                            działania diagnostyczne;
                        </ol>
                        <ol>
                            kompetentne i terminowe wywiązywanie się z przyjętych obowiązków;
                        </ol>
                        <ol>
                            dbałość o należyty wizerunek podmiotu leczniczego;
                        </ol>
                        <ol>
                            zapewnienie sprawności funkcjonowania podmiotu;
                        </ol>
                        <ol>
                            przestrzeganie tajemnicy służbowej, kodeksów etyki zawodowej, karty praw pacjenta;
                        </ol>
                        <ol>
                            wykonywanie innych czynności określonych w poszczególnych zarządzeniach lub poleceniach przełożonego.
                        </ol>
                        <ol>
                            udzielenie pierwszej pomocy doraźnej
                        </ol>
                        <ol>
                            profilaktyka stomatologiczna
                        </ol>
                        <ol>
                            prowadzenie dokumentacji medycznej.
                        </ol>
                    </ListDisc>
                </li>
            </Ol>
        </Paragph>

        <H3>§ 6</H3>
        <Paragph>
            <Ol>
                <li>
                    Świadczenia w MEDIROOT udzielne są zgodnie z harmonogramem -załącznik nr 1
                </li>
                <li>
                    Rejestracja pacjentów w ramach NFZ, wpisanie na listę oczekujących oraz prywatnie dokonywana jest na podstawie zgłoszenia:
                    <Ol>
                        <li>
                            Zapisy są prowadzone w godzinach pracy rejestracji.
                        </li>
                        <li>
                            Zapisy odbywają się osobiście, telefonicznie lub przez osoby trzecie.
                        </li>
                        <li>                            
                            Świadczenia zdrowotne są udzielane pacjentom w terminach z nimi uzgodnionymi.
                        </li>
                        <li>
                            Pacjenci zapisywani są w kolejności zgłoszeń, jeśli świadczenie nie może być udzielone w dniu zgłoszenia, termin jego realizacji jest uzgadniany z pacjentem w rejestracji, następnie pacjent umieszczony jest na liście oczekujących.
                        </li>
                        <li>                            
                            W przypadku zaistnienia nieprzewidzianych okoliczności, które uniemożliwią realizację świadczenia w terminie ustalonym, pacjent jest informowany o zmianie terminu  telefonicznie.
                        </li>
                        <li>
                            W przypadku nagłego zachorowania, wypadku, urazu, zatrucia, stanu zagrożenia życia, a także porodu przyjęcie następuje niezwłocznie.
                        </li>
                        <li>
                            Podczas rejestracji oraz w dniu zgłoszenia się na wizytę do poradni pacjent powinien mieć przy sobie dokument tożsamości.
                        </li>
                        <li>
                            Ustala się opłaty za świadczenia zdrowotne , które nie są finansowanie ze środków  publicznych.
                        </li>
                    </Ol>
                </li>
            </Ol>
            Aktualny cennik umieszczony jest na stronie i stanowi załącznik do niniejszego Regulaminu.
        </Paragph>

        <H3>§ 6</H3>
        <Paragph>
            <Ol>
                <li>
                    Podmiot leczniczy udziela bezpłatnych świadczeń zdrowotnych osobom ubezpieczonym w Narodowym Funduszu Zdrowia, w ramach umowy z Narodowym Funduszem Zdrowia.
                </li>
                <li>
                    Lekarze udzielający świadczeń zdrowotnych w podmiocie leczniczym, kierują na leczenie szpitalne, badania diagnostyczna lub konsultacje specjalistyczne przez wystawienie skierowania.
                </li>
            </Ol>
        </Paragph>

        <H3>§ 7</H3>
        <Paragph>
            <Ol>
                <li>
                    Szczegółowy zakres zadań, praw i obowiązków pracowników zatrudnionych w przedsiębiorstwie podmiotu leczniczego regulują odrębne, wewnątrzzakładowe przepisy o treści ustalonej przez przedsiębiorcę będącego podmiotem leczniczym.
                </li>
                <li>
                    Współdziałanie między poszczególnymi jednostkami oraz komórkami organizacyjnymi podmiotu  odbywa się w sposób, który zapewnia sprawność funkcjonowania podmiotu oraz kompleksowość, dostępność i należytą jakość udzielania świadczeń zdrowotnych.
                </li>
                <li>
                    Podmiot leczniczy powierza wyznaczonemu lekarzowi obowiązek nadzoru w zakresie prawidłowości i jakości udzielanych świadczeń zdrowotnych, koordynacji pracy personelu medycznego zatrudnionego przez podmiot leczniczy oraz nadzoru nad prawidłowością prowadzenia dokumentacji medycznej i przetwarzania danych w niej zawartych.
                </li>
            </Ol>
        </Paragph>

        <H3>§ 8</H3>
        <Paragph>
            <Ol>
                <li>
                 W zakresie wskazanym w ust. 1 udzielane świadczenia zdrowotne mają charakter kompleksowy. W sytuacji, gdy podmiot leczniczy nie jest w stanie zapewnić niezbędnej kompleksowości w ramach udzielanego świadczenia,
                 bądź wymagana jest konsultacja specjalistyczna, osoba udzielająca świadczeń udziela pacjentowi informacji o podmiotach wykonujących działalność leczniczą, które mogą udzielić świadczeń w zakresie wymaganym w danym przypadku.
                </li>
                <li>
                    Podmiot leczniczy prowadzi, przechowuje i udostępnia dokumentację medyczną na zasadach określony w niniejszym Regulaminie, a także na podstawie  Rozporządzenie Ministra Zdrowia z dnia 6 kwietnia 2020 r. w sprawie rodzajów, zakresu i wzorów dokumentacji medycznej oraz sposobu jej przetwarzania.
                </li>
                <li>
                    Podmiot leczniczy zapewnia ochronę danych zawartych w dokumentacji medycznej.
                </li>
                <li>
                    Dokumentację medyczną udostępnia się w szczególności na wniosek:
                    <ListDisc>
                        <li>
                            pacjenta lub jego przedstawiciela ustawowego, bądź osoby upoważnionej przez pacjenta; po śmierci pacjenta, prawo wglądu w dokumentację medyczną ma osoba upoważniona przez pacjenta za życia;
                        </li>
                        <li>
                            podmiotu finansującego udzielanie świadczeń zdrowotnych,
                        </li>
                        <li>
                            organu władzy publicznej, Narodowego Funduszu Zdrowia, organu samorządu zawodów medycznych oraz konsultantów krajowego i wojewódzkiego, w zakresie niezbędnym do wykonywania przez te podmioty ich zadań, w szczególności kontroli i nadzoru;
                        </li>
                        <li>
                            ministra do spraw zdrowia, sądu, prokuratura, lekarza sądowego i rzecznika odpowiedzialności zawodowej, w związku z prowadzonym postępowaniem;
                        </li>
                        <li>
                            organu rentowego oraz zespołu do spraw orzekania o niepełnosprawności, w związku z prowadzonym przez nie postępowaniem;
                        </li>
                        <li>
                            zakładu ubezpieczeń, za zgodą pacjenta.
                        </li>
                    </ListDisc>
                </li>
                <li>
                    Dokumentacja medyczna jest udostępniana:
                    <ListDisc>
                        <li>
                        do wglądu w siedzibie podmiotu leczniczego;
                        </li>
                        <li>
                        poprzez sporządzenie jej wyciągów, odpisów lub kopii;
                        </li>
                        <li>
                        poprzez wydanie oryginału za pokwitowaniem odbioru i z zastrzeżeniem zwrotu po wykorzystaniu,  
        jeżeli uprawniony organ lub podmiot żąda udostępnienia oryginałów tej dokumentacji. 
                        </li>
                    </ListDisc>
                </li>
                <li>
                Za kolejne udostępnienie dokumentacji medycznej podmiot leczniczy pobiera opłatę w wysokości.
                <ListDisc>
                    <li>
                    jedną stronę wyciągu lub odpisu dokumentacji medycznej –0,002 miesięcznego wynagrodzenia -……. zł
                    </li>
                    <li>
                    jedną stronę kopii dokumentacji medycznej – 0,0002 miesięcznego wynagrodzenia -………zł
                    </li>
                    <li>
                    sporządzenie wyciągu, odpisu lub kopii dokumentacji medycznej na elektronicznym nośniku danych, jeżeli dokumentacja medyczna prowadzona jest w postaci elektronicznej – 0,002  miesięcznego wynagrodzenia- …….zł
                    </li>
                </ListDisc>
                Dokumentacja zostanie wydana niezwłocznie , maksymalnie  do 1 tygodnia.
Przeciętne miesięczne wynagrodzenie za poprzedni kwartał ogłasza Prezes GUS w ,, Monitor Polski”
                </li>
            </Ol>
        </Paragph>

        <H3>§ 9</H3>
        <Paragph>
        Pacjentowi korzystającemu ze świadczeń zwrotnych udzielanych w ramach działalności prowadzonej przez podmiot leczniczy przysługują prawa przewidziane w Rozporządzenie Ministra Zdrowia z dnia 6 kwietnia 2020 r. w sprawie rodzajów, zakresu i wzorów dokumentacji medycznej oraz sposobu jej przetwarzania
        <Ol>
            <li>
               a także w przepisach odrębnych.W rejestracji: karta praw i obowiązków pacjenta oraz księga skarg i wniosków.. Przysługuje pacjentowi możliwość złożenia skargi do Rzecznika Praw Pacjenta.
               Telefoniczna Informacja Pacjenta: 800 - 190 - 590 (z tel. stacjonarnych i komórkowych) czynna pn. - pt.
               w godz. 8.00  - 18.00. Biuro Rzecznika Praw Pacjenta ul. Młynarska 46 ,01-171 Warszawa ,
               Sekretariat: tel.: (22) 532 - 82 - 50 fax.:  (22) 506 - 50 - 64  kancelaria@rpp.gov.pl,
               Numery telefonów alarmowych do ratownictwa medycznego  112, 999.
            </li>
            <li>
                Informacja o prawach uwzględniająca ograniczenia tych praw określone w przepisach prawa, udostępniona jest pacjentom w miejscu udzielania świadczeń zdrowotnych w formie pisemnej w miejscu ogólnodostępnym. W przypadku pacjenta niemogącego się poruszać informację taką udostępnia się w sposób umożliwiający zapoznanie się z nią w pomieszczeniu, w którym pacjent przebywa.
            </li>
        </Ol>
        </Paragph>

        <H3>§ 10</H3>
        <Paragph>
        Kierownik jest  przełożonym wszystkich pracowników, kieruje działalnością Zakładu i reprezentuje go  
       na zewnątrz. Kierownik Zakładu wykonując swoją funkcję zapewnia w szczególności:
       <Ol>
        <li>
         koordynację działania Zakładu
        </li>
        <li>
        współdziałanie z innymi podmiotami w zakresie realizacji świadczeń medycznych.  
        </li>
        <li>
         Podejmuje decyzje w sprawach pracowniczych i finansowych
        </li>
        <li>
         Sprawuje nadzór nad wykonywaniem zadań przez pracowników bezpośrednio podległych.
        </li>
        <li>
         Ostatecznie rozpatruje skargi, wnioski i zażalenia pracowników i pacjentów.
        </li>
       </Ol>
       Administratorem danych osobowych jest Urszula Korzeniowska .Klauzula informacyjna stanowi załącznik nr 3.
        </Paragph>

        <H3>§ 11</H3>
        <Paragph>
            <Ol>
                <li>
                    W czasie korzystania ze świadczeń medycznych  pacjent ma prawo do:
                    <ListDisc>
                        <li>
                        poszanowania godności osobistej oraz uprzejmego traktowania przez personel medyczny
                        </li>
                        <li>
                        świadczenia usług zdrowotnych przez personel o odpowiednich kwalifikacjach, zgodnie z wymaganiami    
    wiedzy medycznej
                        </li>
                        <li>                            
                        rzetelnej informacji o stanie zdrowia
                        </li>
                        <li>
                            wyrażenia zgody na udzielanie określonych świadczeń zdrowotnych lub ich odmowy, po uzyskaniu  
    informacji o proponowanych metodach diagnozy i leczenia.
    Do obowiązków pacjenta należy:<br/>
-   przestrzeganie regulaminu organizacyjnego MEDIROOT<br/>
-   przestrzeganie zakazu palenia tytoniu oraz spożywania alkoholu na terenie  placówki<br/>
-   przestrzeganie higieny osobistej<br/>
-   posiadania ważnych dokumentów uprawniających do korzystania z nieodpłatnych świadczeń<br/>
     zdrowotnych
-   przestrzeganie zasad bezpieczeństwa oraz ppoż.<br/>
-   przestrzeganie zaleceń lekarza
                        </li>
                    </ListDisc>
                </li>
                <li>
                Pacjent, którego prawa zostały naruszone w trakcie korzystania ze świadczeń medycznych w  MEDIRIOOT ma prawo do złożenia ustnej lub pisemnej skargi do kierownika placówki adres mailowy rkorzniowski@gmail.com 
                </li>
                <li>
                Pacjenci mają prawo zgłaszania uwag, wniosków i skarg  osobom udzielającym im świadczeń zdrowotnych jak i bezpośrednio kierować je do kierownika placówki adres mailowy rkorzeniowski@gmail.com Na życzenie pacjenta udzielana jest mu odpowiedź na piśmie.
                </li>
                <li>
                Kierownik podmiotu leczniczego MEDIROOT zapewnia dostępność informacji o prawach pacjenta.
                </li>
            </Ol>
        </Paragph>

        <H3>§ 12</H3>
        <Paragph>
        Regulamin wchodzi w życie z dniem podpisania przez kierownika zakładu.
        </Paragph>

  </CenterContainer>
</Layout>);

export default RodoTemplate;


export const query = graphql`
  query {
    logo: file(relativePath: { eq: "images/logo3.png" }) {
      childImageSharp {
        fluid( maxHeight: 92) {
          src
          sizes
          srcSet
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
  `
